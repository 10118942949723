import { useState, useEffect } from "react";
import ProfileImageWithDefault from './ProfileImageWithDefault';
import Input from './Input';
import ButtonWithProgress from './ButtonWithProgress';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useHistory } from 'react-router-dom';
import springBootLogo from '../assets/springboot_2.png';
import reactLogo from '../assets/react_1.png';
import mysqlLogo from '../assets/mysql1.png';
import '../styles/ProfileCard.css';

const ProfileCard = (props) => {
  const history = useHistory();

  const { displayName, username, image } = props.user;

  const showEditButton = props.isEditable && !props.inEditMode;

  //console.log("username = " + username);

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Software Developer | Music Enthusiast"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  let profileContent = (
    <h4>{`${displayName} @${username}`}</h4>
  );
  if (username === 'markvp') {
    profileContent = (
      <div>
        <TrackVisibility>
          {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
              {/* <span className="tagline">Welcome to my Website!</span> */}
              <h1>{`Hi! I'm Mark Valentino | `} <span className="txt-rotate" data-period="1000" data-rotate='[ "Software Developer", "Music Enthusiast" ]'><span className="wrap">{text}</span></span></h1>
              <br />
              <p>I'm a Software Developer with more than six years of experience, escpecially in Java, Spring MVC / Spring Boot, Database, and Microservices.</p>
              {/* <button onClick={() => console.log('connect')}>Let’s Connect <ArrowRightCircle size={25} /></button> */}
              <br />
              <button onClick={() => history.push('/signup')}>Let’s Connect <ArrowRightCircle size={25} /></button>
            </div>}
        </TrackVisibility>
        <br />
        <div className="logo-container">
          <img src={springBootLogo} alt="Java Logo" className="logo rounded-circle" />
          <img src={reactLogo} alt="React Logo" className="logo rounded-circle" />
          <img src={mysqlLogo} alt="MySQL Logo" className="logo rounded-circle" />
        </div>
      </div>
    );
  };

  return (
    <div id="profile-card" className="card" style={{ marginTop: '15px' }}>
      <div className="card-header text-center">
        <ProfileImageWithDefault
          alt="profile"
          width="200"
          height="200"
          image={image}
          src={props.loadedImage}
          className="rounded-circle shadow"
        />
      </div>
      <div className="card-body text-center">
        {!props.inEditMode && profileContent}
        {props.inEditMode && (
          <div className="mb-2">
            <Input
              value={displayName}
              label={`Change Display Name for ${username}`}
              onChange={props.onChangeDisplayName}
              hasError={props.errors.displayName && true}
              error={props.errors.displayName}
            />
            <div className="mt-2">
              {/* Disable File Upload for now */}
              {/* <Input
                type="file"
                onChange={props.onFileSelect}
                hasError={props.errors.image && true}
                error={props.errors.image}
              /> */}
            </div>
          </div>
        )}
        {showEditButton && (
          <div>
            <br />
            <button
              className="btn btn-outline-success"
              onClick={props.onClickEdit}
            >
              <i className="fas fa-user-edit" /> Edit
            </button>
          </div>
        )}
        {props.inEditMode && (
          <div>
            <ButtonWithProgress
              className="btn btn-primary"
              onClick={props.onClickSave}
              text={
                <span>
                  <i className="fas fa-save" /> Save
                </span>
              }
              pendingApiCall={props.pendingUpdateCall}
              disabled={props.pendingUpdateCall}
            />
            <button
              className="btn btn-outline-secondary ml-1"
              onClick={props.onClickCancel}
              disabled={props.pendingUpdateCall}
            >
              <i className="fas fa-window-close" /> Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

ProfileCard.defaultProps = {
  errors: {}
};

export default ProfileCard;
