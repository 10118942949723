import React from 'react';
import '../styles/HomePage.css';
import '../styles/dark-mode.css';
import CommentList from '../components/CommentList';
import SoundCloudIframe from '../components/SoundCloudIframe';
import { connect } from 'react-redux';

// const soundCloudTrackUrl = 'https://soundcloud.com/markvp/mark-vp-ft-misael-running-hillsong-cover-remix-1?si=3715c55bb0af48abb277f681ea52afa8&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing';

const HomePage = (props) => {
  const [darkMode, setDarkMode] = React.useState(false);

  return (
    <div data-testid="homepage" className={darkMode ? 'dark-mode' : ''}>
      <div className="container mt-3 d-flex justify-content-center">
        <div id="mobile-box">
          <div className="card" style={{ border: '1px solid #000' }}>
            <div className="card-content">
              {/* <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                <img
                  className="rounded-2xl mx-auto img-fluid"
                  src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbnZ0ejBnYWhtanJuOTk2ZDViMW9la24zOXhwd2tyeDNwemRqOHZiMiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/tqfS3mgQU28ko/giphy.gif"
                  alt="Cover illustration for Mark VP's song playlist"
                />
                <a href="#!">
                  <div className="mask" style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }} />
                </a>
              </div> */}
              <div className="card-body text-center">
                <SoundCloudIframe />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-2 my-3 col-md-7 justify-content-center">
        <div className="card">
          <CommentList />
        </div>
      </div>
      <br />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state,
  };
};

export default connect(mapStateToProps)(HomePage);
