import React, { useState, useEffect } from 'react';
import * as apiCalls from '../api/apiCalls';
import CommentListItem from './CommentListItem';
import { connect } from 'react-redux';
import '../styles/CommentList.css';

const CommentList = (props) => {
    const [page, setPage] = useState({
        content: [],
        username: '',
        number: 0,
        size: 3
    });

    const [loadError, setLoadError] = useState();
    const [comment, setComment] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadData();
    }, []);

    const loadData = (requestedPage = 0) => {
        apiCalls
            .listComments({ page: requestedPage, size: 5 })
            .then((response) => {
                setPage(response.data);
                setLoadError('');
            })
            .catch((error) => {
                setLoadError('Comments load failed');
            });
    };

    const onClickNext = () => {
        loadData(page.number + 1);
    };

    const onClickPrevious = () => {
        loadData(page.number - 1);
    };

    const { content, first, last } = page;
    const isLoggedIn = props.loggedInUser.isLoggedIn;
    const username = props.loggedInUser.username;

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            //console.log('username @commentList = ', username);

            apiCalls.addComment({ content: comment, username })
                .then((response) => {
                    //console.log('Comment added successfully:', response.data);
                    setSuccessMessage('Thank you. Your comment will be reviewed.');
                    setComment('');

                    // Hide the success message after 5 seconds
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 5000);
                })
                .catch((error) => {
                    console.error('Error adding comment:', error.response);

                    if (error.response) {
                        const apiError = error.response.data;
                        const validationErrors = apiError.validationErrors || apiError.error?.validationErrors;
                        const errMessage = apiError.error.message || apiError.error?.message;

                        if (validationErrors) {
                            // The request was made and the server responded with a validation error
                            // Display the validation errors
                            setErrorMessage(Object.values(validationErrors).join(', '));
                        } else if (errMessage) {
                            setErrorMessage(errMessage);
                        } else {
                            setErrorMessage('An error occurred.');
                            console.error('API Error:', apiError);
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        setErrorMessage('No response received.');
                        console.error('No response received');
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        setErrorMessage('Error setting up the request.');
                        console.error('Error setting up the request:', error.message);
                    }

                    // Clear success message in case of an error
                    setTimeout(() => {
                        setErrorMessage('');
                    }, 5000);
                });
        }
    };

    const [isInputFocused, setIsInputFocused] = useState(false);

    return (
        <div id="comment-list" className="card" style={{ backgroundColor: '#566573', color: '#fff', paddingBottom: '10px' }}>
            <h4 className="card-title m-auto">Leave a comment</h4>
            <div className="list-group list-group-flush" data-testid="commentgroup">
                <input
                    type="text"
                    className={`form-control list-group-item ${isInputFocused ? 'input-zoom' : ''}`}
                    placeholder={isLoggedIn ? "Type here..." : "Please login to comment..."}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    disabled={!isLoggedIn}
                    onKeyDown={handleKeyPress}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    style={{ background: 'white' }}
                />
                {successMessage && (
                    <div className="alert alert-success mt-2">
                        {successMessage}
                    </div>
                )}
                {errorMessage && (
                    <div className="alert alert-danger mt-2">
                        {errorMessage}
                    </div>
                )}
                {content && content.map((comment) => {
                    return <CommentListItem key={comment.id} comment={comment} />;
                })}

            </div>
            <div className="clearfix">
                {!first && (
                    <span
                        className="badge badge-light float-left navigation-span"
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        onClick={onClickPrevious}
                    >
                        {`< Previous`}
                    </span>
                )}
                {!last && (
                    <span
                        className="badge badge-light float-right navigation-span"
                        style={{ cursor: 'pointer', fontSize: '16px' }}
                        onClick={onClickNext}
                    >
                        {`Next >`}
                    </span>
                )}
            </div>
            {loadError && (
                <span className="text-center text-danger">{loadError}</span>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        loggedInUser: state,
    };
};

export default connect(mapStateToProps)(CommentList);