import axios from "axios";

const baseURL = '/api/1.0';

export const signup = (user) => {
    return axios.post(`api/auth/signup`, user);
};

export const login = (user) => {
    //console.log('user apiCalls = ', user);
    return axios.post(`api/auth/login`, user);
};

// Basic Authentication
// export const setAuthorizationHeader = ({ username, password, isLoggedIn }) => {
//     if (isLoggedIn) {
//         axios.defaults.headers.common['Authorization'] = `Basic ${btoa(
//             username + ':' + password
//         )}`;
//     } else {
//         delete axios.defaults.headers.common['Authorization'];
//     }
// };

// JWT Authentication
export const setAuthorizationHeader = (jwtToken) => {
    if (jwtToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

// default parameter : page=0, size=3
export const listUsers = (param = { page: 0, size: 3 }) => {
    const path = `${baseURL}/users?page=${param.page || 0}&size=${param.size || 3}`;
    return axios.get(path);
};

// default parameter : page=0, size=3
export const listComments = (param = { page: 0, size: 3 }) => {
    const path = `${baseURL}/comments?page=${param.page || 0}&size=${param.size || 3}`;
    return axios.get(path);
};

export const addComment = ({ content, username }) => {
    return axios.post(`${baseURL}/comments`, { content, username });
};

export const getUser = (username) => {
    return axios.get(`${baseURL}/users/${username}`);
};

export const updateUser = (userId, body) => {
    return axios.put(`${baseURL}/users/` + userId, body);
};
