import React from 'react';
import { Link } from 'react-router-dom';
import ProfileImageWithDefault from './ProfileImageWithDefault';
import '../styles/CommentListItem.css';

const CommentListItem = (props) => {
    return (
        <Link
            to={`/${props.comment.username}`}
            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center comment-list-item"
        >
            <div className="d-flex align-items-center">
                <ProfileImageWithDefault
                    className="rounded-circle"
                    alt="profile"
                    width="32"
                    height="32"
                    image={props.comment.image}
                />
                <span className="pl-2" style={{ color: '#F4D03F', whiteSpace: 'nowrap', overflowX: 'auto' }}>
                    {`@${props.comment.username} : `}
                </span>
                <span className="pl-2" style={{ color: 'white', whiteSpace: 'nowrap', overflowX: 'auto' }}>
                    {`${props.comment.content} `}
                </span>                                 
            </div>

            <span className="pl-2" style={{ color: '#48C9B0', fontSize: '0.8em'}}>
                {props.comment.commentAge}
            </span>        
        </Link>
    );
};

export default CommentListItem;
