import React from 'react';

const SoundCloudIframe = () => {
  return (
    <div 
      dangerouslySetInnerHTML={{
        __html: `<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/28111781&color=%23423567&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/markvp" title="MarkVP" target="_blank" style="color: #cccccc; text-decoration: none;">MarkVP</a> · <a href="https://soundcloud.com/markvp/sets/best" title="d&#x27; bes+" target="_blank" style="color: #cccccc; text-decoration: none;">d&#x27; bes+</a></div>`
      }}
    />
  );
};

export default SoundCloudIframe;