import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { HashRouter } from 'react-router-dom';
//import reportWebVitals from './reportWebVitals';
//import * as serviceWorker from './serviceWorker';
import App from './containers/App';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';

// const loggedInState = {
//   id: 1,
//   username: 'user1',
//   displayName: 'display1',
//   image: 'profile1.png',
//   password: 'P4ssword',
//   isLoggedIn: true
// };

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

// const actions = {
//   //postSignup : apiCalls.signup
//   postLogin: apiCalls.login
// };

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Provider store={store}>
//     <HashRouter>
//       <App />
//     </HashRouter>
//   </Provider>,

//   //<UserSignupPage actions={actions} />,
//   //<LoginPage actions={actions} />,
//   document.getElementById('root')
// );

//ReactDOM.render(<LoginPage />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

//serviceWorker.unregister();
