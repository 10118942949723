import { createStore, applyMiddleware } from 'redux';
import authReducer from './authReducer';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import * as apiCalls from '../api/apiCalls';

const configureStore = (addLogger = true) => {

    let localStorageData = localStorage.getItem('mvp-auth');

    let persistedState = {
        id: 0,
        username: '',
        displayName: '',
        image: '',
        password: '',
        isLoggedIn: false,
        jwtToken: '',
    };

    if (localStorageData) {
        try {
            persistedState = JSON.parse(localStorageData);
            // Remove password from persistedState
            delete persistedState.password;
            apiCalls.setAuthorizationHeader(persistedState.jwtToken);
        } catch (error) { }
    }

    const middleware = addLogger
        ? applyMiddleware(thunk, logger)
        : applyMiddleware(thunk);

    const store = createStore(authReducer, persistedState, middleware);

    store.subscribe(() => {
        // Create a copy without the password before storing in local storage
        const stateToPersist = { ...store.getState() };
        delete stateToPersist.password;
        delete stateToPersist.id;
        delete stateToPersist.email;
        localStorage.setItem('mvp-auth', JSON.stringify(stateToPersist));
        apiCalls.setAuthorizationHeader(stateToPersist.jwtToken);
    });

    return store;
};

export default configureStore;
